import React from "react";
import { Grid, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import Placeholder from "../../../assets/img/frontend/placeholder2.webp";

const ParraleloSection = ({ columns = [] }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!Array.isArray(columns) || columns.length === 0) {
    return <Typography variant="h6">No data available</Typography>;
  }

  return (
    <Grid container spacing={4} justifyContent="center" sx={{ paddingBottom: "60px", marginBottom: "60px"}}>
      {columns.map((col, index) => (
        <Grid item xs={12} sm={4} key={index} style={{ textAlign: "center", marginTop: "20px", paddingTop: "60px", marginLeft: isMobile? "-15px":"auto" , marginRight: "auto" }}>

          {/* Image in Parallelogram */}
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              height: "250px",
              margin: "0 auto 0 20px",
              backgroundColor: 'transparent',
              backgroundImage: `url(${col.image})`,
              objectFit: "fit",
              backgroundSize: "cover",
              backgroundPosition: "center",
              transform: "skew(-15deg)", // Parallelogram effect
              borderRadius: "0px",
              overflow: "hidden",
            }}
          />

          {/* Label under Image */}
          {/* <Typography variant="h6" color="white" sx={{ mt: 0, fontWeight: 'bold' }}>
            {col.label}
          </Typography> */}
        </Grid>
      ))}
    </Grid>
  );
};

export default ParraleloSection;
