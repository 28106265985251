import React from "react";
import { Box, Typography, Container, useMediaQuery, useTheme } from "@mui/material";
import BackgroundImage from "../../../assets/img/frontend/background-purple-dark-top.webp"; // Replace with actual path
import CenteredImage from "../../../assets/img/frontend/small-s.svg"; // Replace with actual path
import GradientH3 from "../Tyopgraphy/GradientH3";
import ParraleloSection from "../Sections/ParraleloSection";
import africa from "../../../assets/img/frontend/africa.png";

import Partner1 from "../../../assets/img/frontend/Partner1.png";
import Partner2 from "../../../assets/img/frontend/Partner2.png";
import Partner3 from "../../../assets/img/frontend/Partner3.png";

const HeadlineTextReverseShadow = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const columnData = [
    {
      image: `${Partner1}`,
      label: "Only host of WSOP",
    },
    {
      image: `${Partner2}`,
      label: "Only organisers of Alpha 8 Events",
    },
    {
      image: `${Partner3}`,
      label: "Only host of WPT Tour Events",
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        minHeight: "60vh", // Adjust as needed
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "2rem 0",
        background: "transparent"
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: -2,
        }}
      />

      {/* Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(to bottom,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%)",
          zIndex: -1,
        }}
      />

      {/* Content */}
      <Container maxWidth="md">
        {/* Centered Image */}
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "2rem", background: "transparent" }}>
          <img
            src={CenteredImage}
            alt="Centered"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>

        {/* Headline */}
        <Box sx={{ background: "transparent", position: "relative", display: "inline-block" }}>
          {/* Small Background Image */}
          <Box
            component="img"
            src={`${africa}`} // Replace with actual image path
            alt="Decorative Background"
            sx={{
              position: "absolute",
              right: "-70px", // Adjust for perfect positioning
              top: "50%",
              background: "transparent",
              transform: "translateY(-50%)",
              width: "50px", // Adjust size as needed
              height: "auto",
              opacity: 1, // Optional transparency
              zIndex: 2, // Keeps image behind the text
            }}
          />

          {/* Headline */}
          <GradientH3
            fontWeight="bold"
            sx={{ color: "#FFFFFF", marginBottom: "1rem", position: "relative" }}
          >
            Exclusive Achievements In Africa
          </GradientH3>
        </Box>


        {/* Description */}
        {/* <Typography
          variant="h6"
          sx={{ opacity: 0.8, maxWidth: "600px", margin: "0 auto", color: "#FFFFFF" }}
        >
          Get ready for something special—our upcoming events are on the way!        
          </Typography> */}
        <ParraleloSection columns={columnData} />
      </Container>
    </Box>
  );
};

export default HeadlineTextReverseShadow;
