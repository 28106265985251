import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import PokerBanner from "../../../assets/img/frontend/sup-poker-banner.png";
import Woman from "../../../assets/img/frontend/woman.png";
import CustomButton from "../Button/CustomButton";
import CustomText from "../Tyopgraphy/CustomText";
import { Link } from "react-router-dom";
import SouthAfricanFlag from "../../../assets/img/frontend/south-african-flag.jpg";
const HeroBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        position: "relative",
        height: isMobile ? "60vh" : "85vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${PokerBanner})`,
        color: "white",
        padding: isMobile ? "1rem" : "2rem",
      }}
    >
      {/* Woman Image Overlay */}
      <Box
        component="img"
        src={Woman}
        alt="Woman"
        sx={{
          position: "absolute",
          bottom: isMobile ? "-10px" : "-5px",
          right: isMobile ? "-100px" : "100px",
          zIndex: 0,
          background: "transparent",
          width: isMobile ? "400px" : "500px",
          height: "auto",
        }}
      />

      {/* Content Container */}
      <Box sx={{ alignContent: "center", alignItems: "center", position: "relative", zIndex: 1, background: "transparent" }}>
        <CustomText
          variant="h1"
          sx={{
            backgroundImage: `url(${SouthAfricanFlag})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundClip: 'text', 
            WebkitTextFillColor: 'transparent', 
            backgroundClip: 'text',
            color: 'transparent',
          }}
        >
          South Africa
        </CustomText>
        <CustomText variant={isMobile ? "h3" : "h1"}>
          Premier Poker Events
        </CustomText>

        {/* <Typography
          variant={isMobile ? "body1" : "h6"}
          sx={{
            maxWidth: isMobile ? "90%" : "600px",
            opacity: 1,
            margin: "0 auto",
            color: "white",
          }}
        >
          You haven’t played poker until you’ve played a ShuffleUp Poker Event.
        </Typography> */}

        <Box
          sx={{
            display: "flex",
            background: "transparent",
            flexDirection: isMobile ? "column" : "row",
            gap: 2,
            marginTop: "1.5rem",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CustomButton as={Link} sx={{ alignItems: "center !important", alignContent: "center", justifyContent: "center" }} to={'/events/poker-at-caledon-resort'}>
            Next Event &#187;
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroBanner;