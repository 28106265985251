import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Select, MenuItem, Grid, Button, Container, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import RegistrationModal from "../Modals/RegistrationModal";
import Placeholder from "../../../assets/img/frontend/placeholder2.webp";
import SmallTealButtonOutline from "../Button/SmallTealButtonOutline";

import SAPokerOpen1 from "../../../assets/img/frontend/CaledonBanner.png"
import SAPokerOpen2 from "../../../assets/img/frontend/SAOpenPokerEvent2.png"
import SAPokerOpen3 from "../../../assets/img/frontend/SAOpenPokerEvent3.png"
import SAPokerOpen4 from "../../../assets/img/frontend/montecasino_news.webp"
import SAPokerBrand2 from "../../../assets/img/frontend/SAPoker/SAPokerBrand2.webp"
import CustomButton from "../Button/CustomButton";
import TealButton from "../Button/TealButton";

// import Win4 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
// import Win5 from "../../assets/img/frontend/MarkoWinnersPhoto.jpeg";
// import Win6 from "../../assets/img/frontend/ODWSugen.jpeg";
// import Win13 from "../../assets/img/frontend/WesBlom.webp"
// import Win7 from "../../assets/img/frontend/KirithHaria.jpeg";
// import Win8 from "../../assets/img/frontend/AhmedKarriem.jpeg";
// import Win9 from "../../assets/img/frontend/Evasan.jpeg";
// import Win10 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
// import Win11 from "../../assets/img/frontend/CaledonGallery/MuazzGani-HighRoller.jpeg";
// import Win12 from "../../assets/img/frontend/Win3.png";
// import Wes from "../../assets/img/frontend/WesBlom.webp";
// import Benjamin from "../../assets/img/frontend/Benjamin.jpeg"

import SP1 from "../../../assets/img/frontend/SAPoker/SAP1.jpg";
import SP2 from "../../../assets/img/frontend/SAPoker/SAP1.jpg";
import SP3 from "../../../assets/img/frontend/SAPoker/SAP1.jpg";
import SP4 from "../../../assets/img/frontend/SAPoker/SAP1.jpg";
import SAP1 from "../../../assets/img/frontend/SAPoker/SAP1.jpg";
import SAP2 from "../../../assets/img/frontend/SAPoker/SAP2.jpg";
import SAP3 from "../../../assets/img/frontend/SAPoker/SAP3.jpg";
import SAP4 from "../../../assets/img/frontend/SAPoker/SAP4.jpg";
import SAP5 from "../../../assets/img/frontend/SAPoker/SAP5.jpg";
import SAP6 from "../../../assets/img/frontend/SAPoker/SAP6.jpg";
import SAP7 from "../../../assets/img/frontend/SAPoker/SAP7.jpg";

import Win4 from "../../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win5 from "../../../assets/img/frontend/MarkoWinnersPhoto.jpeg";
import Win6 from "../../../assets/img/frontend/ODWSugen.jpeg";
import Win13 from "../../../assets/img/frontend/WesBlom.webp"
import Win7 from "../../../assets/img/frontend/KirithHaria.jpeg";
import Win8 from "../../../assets/img/frontend/AhmedKarriem.jpeg";
import Win9 from "../../../assets/img/frontend/Evasan.jpeg";
import Win10 from "../../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win11 from "../../../assets/img/frontend/CaledonGallery/MuazzGani-HighRoller.jpeg";
import Win12 from "../../../assets/img/frontend/Win3.png";
import Wes from "../../../assets/img/frontend/WesBlom.webp";
import Benjamin from "../../../assets/img/frontend/Benjamin.jpeg"

import CG1 from "../../../assets/img/frontend/CaledonGallery/CG1.png";
import CG2 from "../../../assets/img/frontend/CaledonGallery/CG2.png";
import CG3 from "../../../assets/img/frontend/CaledonGallery/CG3.png";
import CG4 from "../../../assets/img/frontend/CaledonGallery/CG4.png";

const events = [
    {
        id: 6,
        image: `${SAPokerOpen4}`,
        venue: "Monte Casino",
        series: "Splendido 12 Series",
        date: "19 Mar - 30 Mar 2025",
        type: "Past",
        slug: "splendido-12-poker-series", // Slug for dynamic routing
        title: "Splendido Poker Series 12",
        description: "ShuffleUp Poker is excited to announce the Splendido Poker Series 12 at the incredible Montecasino Events & Hotels in Fourways",
        registrationDeadline: "06 Jan 2025",
        eventLink: "/events/splendido-12-poker-series",
        galleryImages: [
            // "https://placehold.co/600x400",
            // "https://placehold.co/600x400"
        ],
        // winners: [
        //     { title: "Winner 1", description: "This is the first winner's story." },
        //     { title: "Winner 2", description: "This is the second winner's story." },
        //     { title: "Winner 3", description: "This is the third winner's story." }
        // ]
    },
    {
        id: 5,
        image: `${CG1}`,
        venue: "Caledon Casino & Resort",
        series: "Caledon Poker Series",
        date: "29 Jan - 2 Feb 2025",
        type: "Past",
        slug: "caledon-series-2025", // Slug for dynamic routing
        title: "Caledon Poker Series",
        description: "ShuffleUp Poker is excited to kickoff the year with the Caledon Poker Series at the incredible Caledon Casino & Resort",
        registrationDeadline: "18 Mar 2025",
        eventLink: "/events/caledon-series-2025",
        // imageCarousel: [
        //     "https://placehold.co/600x400",
        //     "https://placehold.co/600x400",
        //     "https://placehold.co/600x400"
        // ],
        // galleryImages: [
        //     "https://placehold.co/600x400",
        //     "https://placehold.co/600x400"
        // ],
        // winners: [
        //     { title: "Winner 1", description: "This is the first winner's story." },
        //     { title: "Winner 2", description: "This is the second winner's story." },
        //     { title: "Winner 3", description: "This is the third winner's story." }
        // ]
    },
    {
        id: 2,
        image: `${SAPokerOpen1}`,
        venue: "Caledon Casino & Resort",
        series: "Poker at Caledon Resort & Casino",
        date: "3 Jun - 8 Jun 2025",
        type: "Current",
        slug: "poker-at-caledon-resort", // Slug for dynamic routing
        title: "Poker at Caledon Resort & Casino",
        description: "Join us at Caledon Hotel and Casino from 4th to 8th June for an action-packed poker week! Compete in four exciting events, including the 50K Super High Roller, and battle against top players from across Southern Africa.",
        registrationDeadline: "18 Mar 2025",
        eventLink: "/events/poker-at-caledon-resort",
        imageCarousel: [],
        galleryImages: [],
        winners: []
    },
    {
        id: 4,
        image: `${SAPokerOpen4}`,
        venue: "Monte Casino",
        series: "Monte Casino Invitational Poker Series",
        date: "23 Jul - 3 Aug 2025",
        type: "Current",
        slug: "monte-casino-invitational-poker-series", // Slug for dynamic routing
        title: "Monte Casino Invitational Poker Series",
        description: "Join us at Monte Casino in Johannesburg for an unforgettable poker extravaganza from 23rd July to 3rd August. With events catering to all buy-in levels, there's something for every player, whether you're a seasoned pro or just starting out.",
        registrationDeadline: "06 Jan 2025",
        eventLink: "/events/monte-casino-invitational-poker-series",
        imageCarousel: [
          `${SP1}`,
          `${SP2}`,
          `${SP3}`,
          `${SP4}`
        ],
        galleryImages: [
          // "https://placehold.co/600x400",
          // "https://placehold.co/600x400"
        ],
        winners: [
        ]
    },
    {
        id: 3,
        image: `${SAPokerBrand2}`,
        venue: "Monte Casino",
        series: "The SA Poker Open",
        date: "22 Oct - 2 Nov 2025",
        type: "Current",
        slug: "the-sa-poker-open", // Slug for dynamic routing
        title: "The SA Poker Open",
        description: "Get ready for the biggest poker event of the year! The SA Poker Open at Montecasino, from 22nd October to 2nd November, is set to break all previous prize pool records with 9 world-class events. This thrilling series promises to bring together top players from around the globe, offering a poker experience like no other. Whether you're chasing massive prizes or simply looking to be part of history, the SA Poker Open will deliver unforgettable action, excitement, and drama at every turn. With top-tier competition and a vibrant atmosphere, this is the must-attend poker event of the year!",
        registrationDeadline: "06 Jan 2025",
        eventLink: "/events/the-sa-poker-open",
        imageCarousel: [
          `${SAP1}`,
          `${SAP2}`,
          `${SAP3}`,
          `${SAP4}`
        ],
        galleryImages: [
          `${SAP1}`,
          `${SAP2}`,
          `${SAP3}`,
          `${SAP4}`,
          `${SAP5}`,
          `${SAP6}`,
          `${SAP7}`
        ],
        winners: [
            {
                image: `${Win5}`,
                title: "Marko Nortje",
                event: "Main Event",
                description: "R800 000",
                awards: "SA Poker Open 2024",
              },
              {
                image: `${Win10}`,
                title: "Gavin Sardini",
                event: "R10k One Day Event",
                awards: "SA Poker Open 2024",
                description: "R120,000"
              },
              {
                image: `${Win7}`,
                title: "Kirith Haria",
                event: "Mugsy Turbo",
                awards: "SA Poker Open 2024",
                description: "R60,000"
              },
              {
                image: `${Win8}`,
                title: "Ahmed Karrim",
                event: "High Roller",
                awards: "SA Poker Open 2024",
                description: "R1,000,000"
              },
              {
                image: `${Benjamin}`,
                title: "Benjamin Lingenfelder",
                event: "Mystery Bounty",
                awards: "SA Poker Open 2024",
                description: "R49,100"
              },
              {
                image: `${Win9}`,
                title: "Evasan Chettier",
                event: "Freezout",
                awards: "SA Poker Open 2024",
                description: "R62,000"
              },
        ]
    },
];

const EventListSection = ({ type }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [selectedEvent, setSelectedEvent] = useState("All");
    const [sortBy, setSortBy] = useState("date");
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    const navigate = useNavigate();

    const handleViewEvent = (eventSlug) => {
        navigate(`/events/${eventSlug}`);
    };

    // Filter the events based on the 'type' prop passed from parent (Current or Past)
    const filteredEvents = events.filter(event => event.type.toLowerCase() === type.toLowerCase());

    // Further filter based on selectedEvent (optional filter for event series)
    const filteredBySeries = selectedEvent === "All" ? filteredEvents : filteredEvents.filter(event => event.series === selectedEvent);

    const sortedEvents = [...filteredBySeries].sort((a, b) => {
        if (sortBy === "asc") return a.series.localeCompare(b.series);
        if (sortBy === "desc") return b.series.localeCompare(a.series);
        if (sortBy === "date") return new Date(a.date) - new Date(b.date);
        return 0;
    });

    return (
        <Box sx={{ background: "#000", padding: "1rem 0 4rem", color: "#fff" }}>
            <Container maxWidth="lg">

                {/* Header and Filters */}
                <Box sx={{ background: "transparent", display: "flex", flexDirection: isMobile? "column":"row", justifyContent: isMobile? "center":"space-between", alignItems: "center", gap: 2, marginBottom: "1rem", color: "#fff" }}>

                    {/* Left Side: Title, Description, and Event Label */}
                    <Box sx={{ background: "transparent", textAlign: isMobile?"center":"left", width: isMobile? "100%": "80%" }}>
                        {/* <Typography variant="h3" sx={{ fontStyle: "italic", marginBottom: "0.5rem" }}>
                            Upcoming Poker Events
                        </Typography> */}
                        <Typography variant="body1" sx={{ opacity: 1, marginBottom: "1rem", fontSize: "18px" }}>
                            Get ready for Something Special—Our Upcoming Events are on the way!
                        </Typography>
                        {/* <Typography variant="subtitle1" sx={{ color: "#72ECEA", fontWeight: "bold", textTransform: "uppercase" }}>
                            Events:
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: "bold", marginTop: "0.25rem" }}>
                            {selectedEvent}
                        </Typography> */}
                    </Box>

                    {/* Right Side: Filters & Sorting */}
                    <Box sx={{ background: "transparent", display: "flex", alignItems: "center", gap: 2 }}>
                        <Select
                            value={selectedEvent}
                            onChange={(e) => setSelectedEvent(e.target.value)}
                            sx={{ border: "1px solid", borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1", color: "#fff", borderRadius: "4px" }}
                        >
                            <MenuItem value="All">All Events</MenuItem>
                            <MenuItem value="Splendido 12 Series">Splendido 12 Series</MenuItem>
                            <MenuItem value="SA Open Poker Tournament">SA Open Poker Tournament</MenuItem>
                            <MenuItem value="Caledon Series">Caledon Series</MenuItem>
                        </Select>

                        <Select
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value)}
                            sx={{ border: "1px solid", borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1", color: "#fff", borderRadius: "4px" }}
                        >
                            <MenuItem value="asc">Sort A-Z</MenuItem>
                            <MenuItem value="desc">Sort Z-A</MenuItem>
                            <MenuItem value="date">Sort by Date</MenuItem>
                        </Select>
                    </Box>
                </Box>

                {/* Event Grid */}
                <Grid container spacing={2}>
                    {sortedEvents.map((event) => (
                        <Grid item xs={12} key={event.id}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: isMobile? "column":"row",
                                    alignItems: isMobile? "center":"center",
                                    justifyContent: isMobile? "flex-start": "space-between",
                                    alignContent: isMobile? "center":"center",
                                    border: "2px solid transparent",
                                    background: "linear-gradient(180deg, #21174F 16.75%, #0E0B0E 140.05%)",
                                    padding: "0 1rem 2rem",
                                    marginTop: "0px",
                                    borderRadius: "0px",
                                    height: isMobile?"100%":"120px",
                                    overflow: "hidden",
                                    transition: "all 0.3s ease-in-out",
                                    "&:hover": {
                                        borderBottom: "2px solid teal"
                                    }
                                }}
                            >
                                {/* Image */}
                                <Box
                                    component="img"
                                    src={event.image}
                                    alt="Event Image"
                                    sx={{ background: "transparent", width: isMobile? "100%":"125px", height: isMobile?"100%":"125px", borderRadius: "8px", objectFit: "fit", paddinTop: "0px", objectFit: 'cover'}}
                                />

                                <Box
                                    sx={{
                                        position: "relative",
                                        left: "10px",
                                        top: isMobile?"0px":"15px",
                                        bottom: 0,
                                        width: isMobile?"0px":"2px",
                                        height: isMobile? "0px":"50px",
                                        marginRight: "0.5rem",
                                        background: "#72ECEA",
                                        borderRadius: "2px",
                                    }}
                                />

                                {/* Venue */}
                                <Box sx={{ background: "transparent", flex: 1, textAlign: isMobile? "center":"left", paddingLeft: "1rem", marginTop: isMobile? "0px":"" }}>
                                    <Typography variant="caption" sx={{ color: "#72ECEA", fontWeight: "bold", textTransform: "uppercase" }}>
                                        Venue
                                    </Typography>
                                    <Typography variant="body2" sx={{fontSize: isMobile?"20px":"inherit"}}>{event.venue}</Typography>
                                </Box>


                                <Box
                                    sx={{
                                        position: "relative",
                                        display: isMobile? "none":"block",
                                        justifyContent: isMobile? "center":"",
                                        textAlign: isMobile? "center":"",
                                        left: "-10px",
                                        top: isMobile?"0px":"15px",
                                        bottom: 0,
                                        width: isMobile?"0px":"2px",
                                        height: isMobile? "0px":"50px",
                                        marginRight: "0.5rem",
                                        background: "#72ECEA",
                                        borderRadius: "2px",
                                    }}
                                />

                                {/* Series */}
                                <Box sx={{ background: "transparent", flex: 1, textAlign: isMobile? "center" : "left" }}>
                                    <Typography variant="caption" sx={{ color: "#72ECEA", fontWeight: "bold", textTransform: "uppercase", textAlign: isMobile? "center" : "left" }}>
                                        Series
                                    </Typography>
                                    <Typography variant="body2" sx={{fontSize: isMobile?"20px":"inherit"}}>{event.series}</Typography>
                                </Box>


                                <Box
                                    sx={{
                                        position: "relative",
                                        display: isMobile? "none":"block",
                                        left: "-10px",
                                        top: isMobile?"0px":"15px",
                                        bottom: 0,
                                        width: isMobile? "0px":"2px",
                                        height: isMobile? "0px":"50px",
                                        marginRight: "0.5rem",
                                        background: "#72ECEA",
                                        borderRadius: "2px",
                                    }}
                                />

                                {/* Date */}
                                <Box sx={{ background: "transparent", flex: 1, textAlign: isMobile? "center":"left" }}>
                                    <Typography variant="caption" sx={{ color: "#72ECEA", fontWeight: "bold", textTransform: "uppercase", textAlign: isMobile? "center":"left" }}>
                                        Date
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: isMobile?"20px":"inherit"}}>{event.date}</Typography>
                                </Box>

                                {/* Register/View Button */}
                                {type === "Current" ? (
                                    <Box sx={{ background: "transparent", display: "flex", flex:1, flexDirection: isMobile?"column":"row", gap: 3, alignItems: "center" , justifyContent: 'flex-end', width: isMobile?"100%":"auto"}}>
                                    {/* <CustomButton
                                        variant="contained"
                                        color="primary"
                                        width={isMobile? "100%": "auto"}
                                        onClick={handleOpenModal} // Open modal for registration
                                        sx={{ flexShrink: 0, marginTop: "0rem" }}
                                    >
                                        Enquire Now
                                    </CustomButton> */}
                                    <TealButton
                                        component={Link}
                                        to={`/events/${event.slug}`}
                                        variant="contained"
                                        color="primary"
                                        style={{ width: isMobile?"100%":"auto"}}
                                        sx={{ flexShrink: 0, marginTop: "0rem" }}
                                    >
                                        View
                                    </TealButton>
                                    </Box>
                                ) : (
                                    <SmallTealButtonOutline
                                        component={Link}
                                        to={`/events/${event.slug}`}
                                        variant="contained"
                                        color="primary"
                                        sx={{ flexShrink: 0, marginTop: "3rem" }}
                                    >
                                        View
                                    </SmallTealButtonOutline>
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <RegistrationModal open={modalOpen} handleClose={handleCloseModal} />
        </Box>
    );
};

export default EventListSection;
